/*IMPORTANTE: IMPORTAR AQUI foundation.*/
@import './fonts';
@import './variables';
@import './mixins';
@import './tooltips';
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './material_custom.scss';
@import '../../node_modules/primeicons/primeicons.css';
@import '../../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css';
// @import '../../node_modules/primeng/resources/themes/nova/theme.css';
@import '../../node_modules/primeng/resources/primeng.min.css';
@import '../../node_modules/animate.css/animate.min.css';
/* angular-cli file: src/styles.css */
@import '../../node_modules/angular-calendar/css/angular-calendar.css';

/*Estilos mapa leaflet */
@import '../../node_modules/leaflet/dist/leaflet.css';
// @import '../../node_modules/foundation-sites/scss/foundation';
@import './myPopups';
@import '~pretty-checkbox/src/pretty-checkbox.scss';
// @import '~bootstrap/scss/bootstrap';

$xy-grid: false;
$global-flexbox: false;

// @import "../../node_modules/foundation-sites/assets/foundation";

// @include foundation-grid;

// @include foundation-flex-classes;
// @include foundation-flex-grid;

// /*Fuente*/
@font-face {
  font-family: icons-consultia;
  src: url('/assets/fonts/font_consultia/font_consultia.ttf');
}

body {
  /*background-color: #f1f0ea;*/
  background-color: #f7f7f9;
  background-size: cover;
  /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100%;
  height: auto;
  margin: 0;
  font-family: helvetica;
  font-weight: 100;
}

.icon-consultia {
  font-family: 'icons-consultia';
}

// body{
//   font-family: 'Lato';
// }

/* Animations to fade the snackbar in and out */

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes animatetop {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes aparecer {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes desaparecer {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes AparecerDesaparecer {
  from {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeInOutMargin {
  from {
    margin-bottom: -2.65rem;
  }

  25% {
    margin-bottom: -0.5rem;
  }

  75% {
    margin-bottom: -0.5rem;
  }

  to {
    margin-bottom: -2.65rem;
  }
}

@keyframes sombraDecrece {
  from {
    box-shadow: 0 0 0 200vh rgba(255, 255, 255, 0.9);
  }

  to {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  }
}

/********************************************************************************************************************************
**  Utilidades
********************************************************************************************************************************/

.Utils-Oculto {
  display: none !important;
}

.Utils-CentradoHorVer {
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
}

.Utils-Fuente-Movil {

  /*Small screens*/
  @media #{$small_screen_only} {
    font-size: 0.75rem !important;
  }
}

.Utils-Paddings-Movil {

  /*Small screens*/
  @media #{$small_screen_only} {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

.Utils-Margin-Movil {

  /*Small screens*/
  @media #{$small_screen_only} {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}

.column_to_right {
  padding-right: 0px;
}

#Modal_Loading {
  .modal-content {
    height: 50vh;

    .row {
      height: 100%;
    }
  }
}

/********************************************************************************************************************************
**  GLOBAL
********************************************************************************************************************************/

/*TIPOGRAFÍA*/

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato' !important;
}

h1 {
  font-weight: bold !important;
}

fieldset {
  padding: 0.75rem !important;
}

/*----------*/

/*BOTON*/

.button {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.65em 1em;
}

.button.disabled.primary:hover,
.button.disabled.primary:hover,
.button.primary:hover,
.button.primary {
  color: white;
}

.button:active {
  background-color: darken($color_principal, 50);
}

/*----------*/

/*TABLA ESTÁTICA*/

table.TablaNormal {
  thead {
    tr {
      background-color: #cdcbcb;

      th {
        text-align: center;
      }
    }
  }
}

/*----------*/

/*TABLA DINÁMICA*/

table.TablaDinamica {

  td,
  th {
    text-align: center;
  }

  thead {
    tr {
      background-color: #cdcbcb;

      th {
        cursor: pointer;
      }

      th:hover {
        background-color: rgba(0, 0, 255, 0.31);
      }
    }

    tr:hover {
      background-color: #cdcbcb !important;
    }
  }

  tbody {
    tr:hover {
      background-color: rgba(53, 53, 224, 0.18) !important;
    }
  }
}

/*----------*/

/*CURSOR TIPO POINTER*/

a[href],
input[type='submit'],
input[type='image'],
input[type='radio'],
label[for],
select,
.pointer {
  cursor: pointer;
}

/****************************** BARRA HEADER HOME ****************************************************/
.has_scrolled {
  box-shadow: 0px 1px 5px black;
  background-color: #92acbe;
}

/*******************************************************************************************/

/****************************** TOOLTIP ****************************************************/

/* Tooltip container */

.self_tooltip {
  position: relative;
  display: inline-block;
}

.tipso_style {
  border: none;
  cursor: pointer;
}

/*********************************************************************************************/

.fa-spinner {
  animation: loadingSpinner 1s infinite steps(8);
  -webkit-animation: loadingSpinner 1s infinite steps(8);
}

.fa-circle-o-notch {
  animation: loadingSpinner 1.3s infinite linear;
  -webkit-animation: loadingSpinner 1.3s infinite linear;
}

.fa-star--amarilla {
  color: #fbc02d;
}

/**********************************************************************************************/

/*                         BOTÓN CALENDARIO                                                   */

/**********************************************************************************************/

.button_calendar {
  //width: 10rem;
  color: black;
  background-color: white;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  border: 1px solid #cacaca;

  &:hover {
    background-color: $color_primario;
    color: white;
  }

  &:focus {
    background-color: $color_primario;
    color: white;
  }
}

/*********************************************************************************************/

/**********************************************************************************************/

/*                         BOTÓN INHABILITADO                                                 */

/**********************************************************************************************/

.button.disabled,
.button[disabled] {
  opacity: 0.25;
}

/*********************************************************************************************/

/*                         PAGINATION                                                         */

/**********************************************************************************************/

.pagination {
  text-align: center;

  li {
    display: inline-block;
  }

  li.active {
    background-color: $color_principal;

    a {
      color: white;
    }
  }
}

/**********************************************************************************************/

/*                         MODAL                                                              */

/**********************************************************************************************/

/* The Modal (background) */

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1250;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8);
  /* Black w/ opacity */
}

/* Modal Content/Box */

.modal-content {
  background-color: #fefefe;
  margin: 10vh auto;
  /* 15% from the top and centered */
  padding: 1rem;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
  max-width: 62rem;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.2s;
  animation-name: animatetop;
  animation-duration: 0.2s;
}

.modal-buzon,
.modal-presentacion,
.modal-detalle {
  .modal-content {
    margin: 5% auto;
  }
}

@media #{$small_screen_only} {
  .modal-content {
    width: 100%;
  }
}

/*********************************************************************************************/

/**********************************************************************************************/

/*                         BOTON DE CERRAR                                                    */

/**********************************************************************************************/

/* The Close Button */

.close {
  color: #616161;
  float: right;
  font-size: 34px;
  font-weight: bold;
  display: inline;
  transition: 0.25s;

  &:hover,
  :focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

/*********************************************************************************************/

/**********************************************************************************************/

/*                         AUTOCOMPLETE                                                       */

/**********************************************************************************************/

.autocomplete input {
  margin: 0;
}

.autocomplete {
  a {
    color: black;
  }

  //width: 80%;
  float: left;
}

/*********************************************************************************************/

/**********************************************************************************************/

/*                         Multiusuario                                                       */

/**********************************************************************************************/
//Selección proyectos
#seleccion_proyecto {
  background-color: rgba(148, 148, 148, 0.09);
  margin-bottom: 1em;
  padding: 1em;
}

.modal_seleccion_proyecto_opener_holder {
  text-align: center;
}

.modal_seleccion_proyecto_opener {
  font-size: 1rem !important;
}

#modal_seleccion_proyecto_content {
  height: 75%;
  overflow: auto;
  padding: inherit;
}

#modal_seleccion_proyecto .proyecto {
  color: #464646;
}

.proyecto {
  font-weight: bold;
  margin-right: 5px;
}

.selector_proyecto {
  border: 1px solid white;
  margin: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;

  .proyecto_seleccionado_holder {
    display: none;
  }

  .proyecto_seleccionado_holder table {
    margin-bottom: auto;
  }

  .proyecto_seleccionado_holder table tbody {
    border: none;
    background-color: inherit;
  }

  .proyecto_seleccionado_holder table tbody tr td {
    padding: 0;
  }

  .proyecto_seleccionado_holder table tbody tr td:first-child {
    width: 92% !important;
  }

  .proyecto_seleccionado_holder table tbody tr td:last-child {
    width: 8% !important;
  }

  .proyecto_seleccionado_holder table tbody tr td label {
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    color: black !important;
    font-weight: normal !important;
  }

  .alert {
    background-color: #ec5840 !important;
  }

  #proyecto-input,
  #proyecto-input:hover {
    background-color: #fefefe;
    color: black;
  }

  #proyecto-input::placeholder {
    color: darkgray !important;
  }

  .proyecto_seleccionado {
    margin-top: 5px;
  }
}

.proyecto_seleccionado_label {
  font-size: 16px !important;
  cursor: pointer;
  font-weight: bold;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.proyecto_seleccionado_label:hover {
  background-color: rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

//Selección viajeros
.modal_seleccion_viajero_opener_holder {
  text-align: center;
}

.modal_seleccion_viajero_opener {}

#modal_seleccion_viajero_content {
  height: 75%;
  overflow: auto;
  padding: inherit;
}

#modal_seleccion_viajero .viajero {
  color: #464646;
}

.viajero {
  font-weight: bold;
  margin-right: 7px;
}

.departamento {
  color: white;
  background-color: gray;
  font-size: small;
  padding: 5px;
  margin-right: 7px;
}

.empresa {
  color: green;
  margin-right: 7px;
}

.selector_viajero {
  border: 1px solid white;
  margin: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;

  .viajero_seleccionado_holder {
    display: none;
  }

  .viajero_seleccionado_holder table {
    margin-bottom: auto;
  }

  .viajero_seleccionado_holder table tbody {
    border: none;
    background-color: inherit;
  }

  .viajero_seleccionado_holder table tbody tr td {
    padding: 0;
  }

  .viajero_seleccionado_holder table tbody tr td:first-child {
    width: 92% !important;
  }

  .viajero_seleccionado_holder table tbody tr td:last-child {
    width: 8% !important;
  }

  .viajero_seleccionado_holder table tbody tr td label {
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    color: black !important;
    font-weight: normal !important;
  }

  .alert {
    background-color: #ec5840 !important;
  }

  #viajero-input,
  #viajero-input:hover {
    background-color: #fefefe;
    color: black;
  }

  #viajero-input::placeholder {
    color: darkgray !important;
  }

  .viajero_seleccionado {
    margin-top: 5px;
  }
}

.viajero_seleccionado_label {
  font-size: 16px !important;
}

.ui-widget-content {
  background: #ffffff !important;
}

/*********************************************************************************************/

/**********************************************************************************************/

/*                         Geocoding                                                          */

/**********************************************************************************************/

#Geocoding,
#Geocoding_origen,
#Geocoding_destino {
  display: none;
  width: 100%;
  height: 100%;
}

#geocoding_map,
#geocoding_map_origen,
#geocoding_map_destino {
  height: 40vh;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px) and (min-width: 300px) {
    height: 100vh;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}

// TODO: DESCOMENTAR
// html {
//   background-image: url(../assets/img/img_login/p5bn3.jpg);
//   background-size: cover;
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   background-color: #464646;
//   position: absolute;
//   height: 100vh;
//   width: 100%;
//   z-index: 0;
// }

html2 {
  background-image: url(../assets/img/img_login/p5bn3.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #464646;
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.g-recaptcha {
  margin: 0 auto;
}

.leaflet-container a {
  color: black !important;

  &:hover {
    color: black !important;
  }

  &.leaflet-disabled {
    color: #bbb !important;
  }
}

body {
  /*background-color: #f1f0ea;*/
  background-color: #f7f7f9;
  background-size: cover;
  /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  min-height: 100vh;
  height: auto;
  margin: 0;
  font-family: helvetica;
  font-weight: 100;
}

/* Evitar doble scroll cuando se abra un modal (ngBootsrap) */
body.modal-open {
  overflow-y: hidden !important;
}

.unicoViajero {
  background: white;
  padding: 18px 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
}

.unicoViajeroHotel {
  background: white;
  height: 2.4375rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.enlaceReglas {
  text-decoration: underline;
  cursor: pointer;
}

.inline-block {
  display: inline-block;
}

.boton-primario {
  background-color: $color_primario;
  border: 1px solid $color_secundario;
  color: white;

  &:not(:disabled) {
    &:hover {
      background-color: $color_secundario;
    }
  }

  &:hover {
    color: white;
  }

  &:focus {
    background-color: $color_secundario;
    color: white;
  }
}

.boton-primario-reverso {
  background-color: $color_secundario;
  border: 1px solid $color_primario;
  color: white;

  &:hover {
    background-color: $color_primario;
    color: white;
  }

  &:focus {
    background-color: $color_primario;
  }
}

.boton-primario-hollow {
  background-color: white;
  border: 1px solid $color_secundario;
  color: #6a88b1;

  &:hover {
    color: white;
    background-color: $color_secundario;
  }

  &:focus {
    background-color: $color_secundario;
  }
}

.boton-primario-transparente {
  background-color: transparent;
  border: 1px solid white;
  color: white;

  &:hover,
  :focus {
    color: white;
    background-color: $color_secundario;
  }
}

.boton-alerta {
  background-color: $color_alerta;
  border: 1px solid $color_alerta_secundario;
  color: white;

  &:hover {
    color: white;
    background-color: $color_alerta_secundario;
  }

  &:focus {
    background-color: $color_alerta_secundario;
  }
}

.boton-silent {
  background-color: white !important;
  color: #465f8b;
  font-weight: 600 !important;

  &:hover,:focus {
    border: 1px solid white !important;
    color: #465f8b !important;
  }
  &:not(:hover){
    border: 1px solid white !important;
    color: #465f8b !important;
  }
}

.w-50-auto {
  width: 50%;
  margin: auto;
}

.flex {
  display: flex;

  &-wrap {
    flex-wrap: wrap;
  }

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.pvp-decimales {
  font-size: 65%;
}

.show {
  display: block;
}

.w-100 {
  width: 100%;
}

.w-98 {
  width: 98%;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-2 {
  width: 2% !important;
}

.ms-auto {
  margin-left: auto;
}

.ui-multiselect-panel {
  // top: auto !important;
  margin-top: 0.2em;
}

.bold {
  font-weight: bold;
}

.font-weight-bold-600{
  font-weight: 600;
}

.cuerpo-disp__titulo {
  text-align: center;
  color: white;
}

.cuerpo-disp__label {
  font-weight: bold;
  color: #3e608f;
}

.cuerpo-modal-disp__label {
  font-size: 14px;
  font-weight: bold !important;
  color: black !important;
  text-align: left !important;
}

.cuerpo-modal {
  font-weight: bold;
  color: black;
  text-align: center;
}

// .amcharts-chart-div a {
//   display: none !important;
// }

.ngx-pagination .current {
  background: #3e608f !important;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.b-white {
  background-color: white !important;
}

.multiselect__texto {
  font-size: 16px;
  font-family: 'lato';

  &--seleccionado {
    padding: 5px 0;

    span {
      display: flex;
      align-items: center;
    }
  }
}

.font-size-1 {
  font-size: 1rem;
}

.margin-navbar {
  margin-top: 4.5em !important;
}

.space-around {
  justify-content: space-around;
}

.p-rating-icon {
  font-size: 20px !important;
  color: #fbc02d !important;

  &:hover {
    color: #f9a825 !important;
  }
}

.text-left {
  text-align: left !important;
}

.margin-label {
  // margin-left: 0.3em;
}

.cursor-info {
  cursor: help;
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.no-clickable {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.color-warning {
  color: #ffa000;
}

.IE {
  text-align: center;

  &__modal {
    font-size: 1.4em;
    margin: 1rem 0;
  }
}

.fa-rotate-ida {
  //margin-right: .5rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.fa-rotate-vuelta {
  //margin-right: .5rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.centimos {
  font-size: 80%;
}

input::placeholder {
  color: #818181;
}

.p-button:focus {
  box-shadow: 0 0 0 0.1rem #546c8c;
}

.p-selectbutton .p-button {
  background-color: white;
  color: #333;
}

.p-selectbutton .p-button.p-highlight {
  background-color: #6a88b1 !important;
  border-color: #546c8c !important;
}

.aviso-demo {
  font-weight: bold;
  font-style: italic;
}

// .bread-crumb {
//   margin: 1rem 0 1rem 0;
// }

.bread-crumb-icon {
  font-size: 2rem;
  margin-right: 1rem;
  cursor: pointer;
  color: #18314f;
}

.bread-crumb-greather-than {
  font-size: 2rem;
  color: #18314f;
}

.bread-crumb-menu {
  font-size: 2rem;
  margin-left: 1rem;
  letter-spacing: 1px;
  color: #18314f;
}

.margin-list {
  margin-top: 15px;
}

/*********************************************************************************************/

/**********************************************************************************************/

/*                       Estilos para las nuevas tablas de administración                                                  */

/**********************************************************************************************/

.box-results {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
}

.p-input-icon-left>.p-inputtext {
  padding-left: 2rem;
}

.filtro-flex {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.busqueda-tabla {
  margin: 0.4rem 1.7rem 0 0;

  @media (max-width: 600px) {
    margin: 0 0 0.4em 0;
  }
}

.p-dropdown {
  width: 100%;
}

// html {
//   position: relative;
// }

// body {
//   padding-bottom: 45px;
// }

.ver-mas {
  font-size: 80%;
  font-style: italic;
}

// .row {
//   max-width: 80rem;
// }

.texto-nota {
  color: #9e9e9e;
  font-style: italic;
  font-size: 13px;
  font-weight: bold;
}

.sin-borde {
  border: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.custom-badge {
  background-color: #1779ba !important;
  cursor: pointer;
  color: white;
}

/*********************************************************************************************/

/**********************************************************************************************/

/*                       Container personalizado, más ancho que el original de bootstrap      */

/**********************************************************************************************/

.contenedor-custom {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .contenedor-custom {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .contenedor-custom {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .contenedor-custom {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .contenedor-custom {
    max-width: 1386px;
  }
}

.formulario {
  padding: 0.5em 0;

  & input,
  select,
  button {
    margin-bottom: 0.5em !important;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.p-1 {
  padding: 1em;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #6a88b1;
}

.sombra {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 24%);
  transition: all 0.6s;
}

a {
  text-decoration: none !important;
}

.contenedor-input__input {
  width: 100%;
  border: 0;
  font-size: 24px;
  padding: 0;
  font-weight: inherit;
  background: inherit;

  &:focus {
    box-shadow: none !important;
    border: 0 !important;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    font-size: 15px;
  }

  &--lugares {
    font-size: 18px;
  }
}


// Para ocultar el chevron de los dropdown de primeNG
p-dropdown.no-chevron {
  span.p-dropdown-trigger-icon {
    visibility: hidden;
  }
}

/*******************************************************************/

/*******************************************************************/

/*      Estilos corporativos para tablas dinámicas de PrimeNg      */

/*******************************************************************/

.dtx-tabla-dinamica {
  .p-button.boton-primario {
    background-color: $color_secundario !important;
    border: 1px solid $color_primario !important;
    color: white !important;

    &:focus {
      background-color: $color_primario !important;
    }
  }

  .p-button.boton-primario:enabled:hover {
    background-color: $color_primario !important;
    color: white !important;
  }

  /*********paginator**********/
  p-paginator {
    .p-paginator .p-paginator-current {
      color: #6c757d;
    }

    .p-paginator .p-dropdown .p-dropdown-label {
      line-height: 15px;
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
      background: $color_secundario;
      border-color: $color_secundario;
    }

    .p-paginator-last,
    .p-paginator-next,
    .p-paginator-prev,
    .p-paginator-first,
    .p-paginator-page:not(.p-highlight) {
      color: $color_secundario !important;

      &:hover {
        color: $color_secundario !important;
      }
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: #ffffff;
      background: #3e608f;
    }
  }

}

/*************************************************************************************/
/*Modales PRIMENG **/

/*Modales con entrycomponent **/
p-dynamicdialog {
  .p-dialog {
    .p-dialog-header {
      color: white !important;
      background-color: #6a88b1 !important;

      span.p-dialog-header-close-icon {
        color: white !important;
      }
    }

    .p-dialog-content {
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
}


/*Modales sin entrycomponent **/
.dtx-dialog-container {
  .p-dialog {
    .p-dialog-header {
      color: white !important;
      background-color: #6a88b1 !important;

      span.p-dialog-header-close-icon {
        color: white !important;
      }
    }

    .p-dialog-content {
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }

    .importe {
      font-weight: bold !important;

      &__con-reembolso {
        color: green !important;
      }

      &__sin-reembolso {
        color: red !important;
      }
    }
  }
}

/*************************************************************************************/

/*      Modificación estilos y responsive para tablas dinámicas de PrimeNg en
Mis Presupuestos
y autorizaciones-viajes     */

/*************************************************************************************/
// estilos
.dtx-contenedor-tablas {
  .p-datatable-header {
    background-color: #6a88b1 !important;
    color: white;
  }
}

#autorizacionesViajesListado,
#autorizacionesPendientesViajesListado {
  .p-multiselect-filter-container {
    display: none;
  }

  p-tag {
    opacity: .5;
  }

  .text.icono-producto {
    color: #6a88b1;
  }
}

#campanaAvisos {
  .p-badge {
    font-size: 0.5rem;
    min-width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
  }
}

// responsive
.dtx-tabla-dinamica,
.contenedor-principal__modal {
  .p-datatable-responsive-custom .p-datatable-tbody>tr>td .p-column-title {
    display: none;
  }

  .p-datatable-responsive-custom .p-datatable-tbody>tr>td.icon-container {
    text-align: center !important;
    cursor: pointer;
  }

  .p-datatable-responsive-custom .p-datatable-tbody>tr>td.button-container {
    text-align: right !important;
  }

  .p-datatable-responsive-custom .p-datatable-tbody>tr>td.celda-precio__vuelos {
    padding: .8rem 0 1rem 1rem !important;
    text-align: right;
  }

  .p-datatable-responsive-custom .p-datatable-tbody>tr>td.celda-precio {
    text-align: right;
  }
}

// @media screen and (max-width: $large_tablet_breakpoing_width) {
// 64em; // 1024px //
@media screen and (max-width: 1150px) {

  #comunidadosListado.dtx-tabla-dinamica,
  #misPresupuestosListado.dtx-tabla-dinamica,
  #autorizacionesViajesListado.dtx-tabla-dinamica,
  #autorizacionesPendientesViajesListado.dtx-tabla-dinamica,
  #miPresupuestoDetalleModal.contenedor-principal__modal {
    .p-datatable {
      &.p-datatable-responsive-custom {

        .p-datatable-thead>tr>th,
        .p-datatable-tfoot>tr>td {
          display: none !important;
        }

        .p-datatable-tbody>tr>td {
          text-align: left;
          display: block;
          width: 100%;
          float: left;
          clear: left;
          border: 0 none;

          &.button-container {
            text-align: right !important;
          }

          &.icon-container {
            text-align: left !important;
          }

          &.celda-precio__vuelos {
            padding: 1rem 1rem !important;
          }

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4em 1em -.4em -.4rem;
            font-weight: bold !important;
          }

          &:last-child {
            border-bottom: 1px solid var(--surface-d);
          }
        }
      }
    }
  }
}

/*************************************************************************************/

/*      Estilos y responsive mis presupuestos vista previa */

/*************************************************************************************/

.documentoPdf {
  .p-panel-header {
    font-weight: 600 !important;
    border: 1px solid #6a88b1 !important;
    background: rgba(106, 136, 177, 0.65) !important;
  }

  .p-panel-content {
    padding: 0 !important;

    .principal {
      padding: .5em !important;
    }

    .contenido-panel--tarjeta {
      padding-top: 0.5em;

      div.p-card.p-component {
        // height: 100%;

        box-shadow: none !important;
      }

      div.p-card-header {
        padding: .5em !important;
        font-weight: 500 !important;
      }

      div.p-card-body {
        padding: 0.5em !important;
      }

      .contenedor-imagen-proveedor {
        justify-content: flex-start;
        gap: .5em;

        .imagen-proveedor {
          max-height: 1.5em;
          max-width: 100%;
        }
      }

      hr {
        border-top: 1px dashed !important;
      }

    }
  }

  .producto--vuelo,
  .producto--tren,
  .producto--barco {
    .p-panel-content .contenido-panel--tarjeta {
      div.p-card-body {
        border-right: 1px solid lightgrey;
        border-left: 1px solid lightgrey;
      }
    }
  }

  .contenido-tarjeta {
    div.p-card-header {
      padding: .5em !important;
      font-weight: 500 !important;
    }

    div.p-card-body {
      padding: 0.5em !important;
    }
  }

  .dtx-icon i {
    color: #6a88b1 !important;
  }
}

/*************************************************************************************/

.delete-icon {
  color: red;
  border-color: red;
  font-size: 1.5em;
  cursor: pointer;
}

.dropdown-no-padding {
  & .p-dropdown-item.p-ripple {
    padding: 0 !important;
  }

  & .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    & .no-cumple {
      background: #a5a5a5 !important;
    }

    &>div:first-child {
      padding: 0.5rem 1.5rem !important;
    }
  }
}

//Truncar texto calendario
.litepicker .container__months .month-item-weekdays-row>div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*************************************************************************************/
// custom-chip-button misPptos //
/*************************************************************************************/
.dtx-chip {
  font-family: 'Lato' !important;
  cursor: pointer;

  &.p-chip {
    padding: 0.5em 0.5em;
    justify-content: center;
    line-height: 12px;

    span.p-chip-icon {
      font-size: 15px;
    }
  }

  &__boton-anyadir--presupuesto {
    font-size: 10px;
    font-weight: 600;
    color: #005500;
    height: 28px;
    background-color: #e5f5e5;

    &.transfer {
      margin-bottom: 20px;
    }

    &.hotel {
      margin-bottom: 12px;
    }

    &.rentacar {
      margin-bottom: 10px;
    }
  }

  &__boton-solicitar--autorizacion {
    font-size: 10px;
    font-weight: 600;
    color: #ef5350;
    height: 28px;
    background-color: #f8dbdb;

    &.vuelo {
      margin-top: 10px;
    }

    &.hotel {
      margin-bottom: 10px;
      width: 80%;
    }

    &.disabled {
      opacity: 0.25;
      cursor: none;
      pointer-events: none;
    }
  }
}

// botón solicitar autorización
.boton-solicitar--autorizacion {
  font-size: 14px;
}

/*Botones PRIMENG **/
.boton-verde {
  background-color: $color_boton_verde !important;

  &:hover {
    background-color: lighten($color: $color_boton_verde, $amount: 40) !important;
  }
}

.boton-rojo {
  background-color: $color_boton_rojo !important;

  &:hover {
    background-color: lighten($color: $color_boton_rojo, $amount: 20) !important;
  }
}

.pax-button {
  padding: 0.25em !important;
}

/*p-columnfilter con multiselects PRIMENG: eliminar espacio botón clear filter oculto lado derecho **/
#autorizacionesEnviadasListado,
#autorizacionesRecibidasListado {
  button.p-column-filter-clear-button {
    display: none !important;
  }
}

/*p-datatable-wrapper con overflow scroll para que el cuerpo de la tabla no sobresalga del documento contenedor **/
#autorizacionesEnviadasListado,
#autorizacionesRecibidasListado {
  .p-datatable-wrapper {
    overflow-x: scroll !important;
  }
}

/*SWEET-ALERTS CON Z-INDEX PARA LISTA DOCUMENTOS PERFIL VIAJEROS PRERRESERVA**/
.swal-z-index-9999 {
  z-index: 9999 !important;
}

/* TOOLTIP RESIDENTE EN BARCOS */
div.p-tooltip.info-tooltip-residente {
  div.p-tooltip-text {
    width: 250px;
  }
}

/*************************************************************************************/
// tabView con distinto estilo que en homepage //
/*************************************************************************************/
.dtx-tabPanel,
.dtx-tabPanel-mapa {
  &__center {
    ul.p-tabview-nav {
      justify-content: center !important;
    }
  }

  .p-tabview .p-tabview-nav .p-tabview-nav-link {
    color: lightgrey;
    border: none;
    font-size: .75rem !important;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #7087ae;
    border: none;
    border-bottom: 2px solid $color-primario;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }

  .p-tabview {
    padding: 0;
  }

  .p-tabview .p-tabview-nav{
    background: white !important;
  }

  .p-tabview .p-tabview-panels{
    padding: .25rem !important;
    margin-left: 0.5rem;
  }

  .p-tabview-panels {
    padding-top: 1em;
  }

  .seleccionado {
    span.p-tabview-right-icon {
      color: #0bd10b;
      font-weight: 800;
    }
  }
  .p-button:focus {
    box-shadow: none !important;
    color:#00376a;
  }
  .p-button.p-button-text{
    color:#00376a !important;
    font-weight: 500;
    .p-button-icon{
      color: #c1cfd9 !important;
    }
  }
}

.dtx-tabPanel-mapa {
  .p-tabview {
    padding-bottom: 1rem;
  }
  // tabs cabinas
  .p-tabview-nav-container {
    display: flex;
    justify-content: center;

    .p-tabview-nav-content {
      border-radius: 8px;
      border: solid 1px #c1cfd9;
      background-color: #f7f7f9;
      width: fit-content;
      padding: .5rem;

      .p-tabview-nav-link {
        background-color: #f7f7f9 !important;
      }
    }
  }

  .p-tabview .p-tabview-nav {
    border: none;
  }

  .p-tabview .p-tabview-nav .p-tabview-nav-link {
    color: lightgrey;
    border: none;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #7087ae;
    border-bottom: 2px solid $color-primario;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
}

@media screen and (max-width: 768px) {
  .dtx-tabPanel,
  .dtx-tabPanel-mapa
  {
    .p-tabview .p-tabview-nav .p-tabview-nav-link {
      font-size: .75rem !important;
    }
  }
}

.dtx-tabPanel-mapa.unicabina {
  .p-tabview-nav, .p-tabview-nav-container {
    visibility: hidden !important;
  }
}

.color-gris{
  color: #c1cfd9;
}

.color-verde {
  color: #0bd10b !important;
}


/*************************************************************************************/
// Colores iconos prime //
/*************************************************************************************/
.swal2-icon.swal2-warning {
  color: #ffc700 !important;
}

#tablaTarificacionAsientos, #tablaResumenAsientosVuelo {
  .p-datatable .p-datatable-tbody>tr {
    border-bottom: 1px solid lightgray !important;
  }

  .text-bold {
    font-weight: bold !important;
  }
}

/*************************************************************************************/
// DTX-CAROUSEL //
/*************************************************************************************/
.dtx-carousel {
  p-carousel {
    .p-carousel-container{
      button{
        align-self: flex-start;
        margin-top: 15rem !important;
      }
    }
    .p-carousel-prev,
    .p-carousel-next {
      width: 3rem !important;
      height: 3rem !important;
      @media screen and (max-width: $tablet_breakpoint_width) {
        margin: 0 !important;
      }
      span.pi-chevron-left,
      span.pi-chevron-right {
        font-size: 3rem;
      }
    }
    .p-carousel-indicators{
      display: none;
    }
  }

  p-carousel.sin-mapas{
    .p-carousel-next.p-link,
    .p-carousel-prev.p-link {
    display: none !important;
  }
  }

  .p-carousel-next.p-link.p-disabled,
  .p-carousel-prev.p-link.p-disabled {
    visibility: hidden !important;
  }

  /* .p-carousel.p-component.p-carousel-horizontal { // si se quiere fijar todo excepto el mapa asientos
    height: 50vh !important;
    overflow-y: scroll !important;
  } */

  .p-carousel-item{
    max-height: 0;
  }
  .p-carousel-item-active{
    max-height: 100% !important;
  }
}

.encabezado-fixed {
  position: fixed !important;
  z-index: 999 !important;
  top: 0 !important;
  width: -moz-available !important;
  width: -webkit-fill-available !important;
}

.tabs-fixed {
  position: fixed !important;
  z-index: 999 !important;
  top: 60px !important;
  width: -moz-available !important;
  width: -webkit-fill-available !important;
}

.boton-inferior-fixed {
  position: fixed !important;
  z-index: 999 !important;
  bottom: 75px;
  right: 5px;
}

@media screen and (max-width: 768px) {
  .dtx-carousel
  {
    .p-carousel-prev.p-link,
    .p-carousel-next.p-link {
      width: 1.5rem !important;
      height: 1.5rem !important;

      span.pi-chevron-left,
      span.pi-chevron-right {
        font-size: 1.5rem !important;
      }
    }
  }
}

/*************************************************************************************/
// COMPONENTES DE PRIME NG CON FUENTE LATO  //
/*************************************************************************************/

.p-component{
  font-family: 'Lato' !important;
}

/*************************************************************************************/
// Chips para varias estaciones trenes en disponibilidad //
/*************************************************************************************/
p-chip#chipEstacion{
  div.p-chip{
    border: 1px solid #ffa301 !important;
    background-color: #ffe6c8 !important;
    color: black !important;
    margin: 0.25rem !important;
  }
}

article.alerta-naranja{
  border: 1px solid #ffa301 !important;
  background-color: #ffe6c8;
  color: black;
  i {
    color: #ffa301;
  }
  .cerrar-alerta{
    color: rgba(0, 0, 0, 0.5);
  }
}

/*************************************************************************************/
// Overlay panel muestra info asiento //
/*************************************************************************************/
.p-overlaypanel{
 z-index: 888 !important;
}

/*************************************************************************************/
// TABLA SENCILLA DE PRIME NG //
/*************************************************************************************/
#tablaSeleccionComplementosVuelo.dtx-tabla-sencilla .p-datatable-thead{
  border-top: transparent;
}

#tablaTarificacionComplementos.dtx-tabla-sencilla .p-datatable-header{
  border-top: transparent;
}

#tablaTarificacionComplementos.dtx-tabla-sencilla .p-datatable-header{
  background: none;
  text-align: left;
}

#tablaTarificacionComplementos.dtx-tabla-sencilla .p-datatable-thead, #tablaTarificacionComplementos.dtx-tabla-sencilla .p-datatable-tbody{
  font-size: 14px !important;
}

/*************************************************************************************/
// TEXTO VALIDACIÓN MAT-ERROR CUANDO EL VALIDATOR INCLUYE VARIOS CAMPOS Y HAY QUE PONERLO FUERA DE MAT-FORM-FIELD. Ajuste padding-top del resto de textos específicos de cada campo //
/*************************************************************************************/

mat-error.form-group-error-text{
    font-size: 75%;
    font-weight: 400;
    line-height: 1.125;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
}

.nuevo-usuario-form  mat-error:not(.form-group-error-text){
  padding-top: 14px;
}

/*************************************************************************************/
// MODAL ELEGIR AUTORIZADORES //
/*************************************************************************************/

.elegir-autorizadores-dialog{
  width: 80%;
  height: 80%;
  @media screen and (min-width: 768px){
    width: 40%;
    height: 60%;
  }
}

/*************************************************************************************/
// BUSCADOR DEL MAT-SELECT ELEGIR PREFIJO INTERNACIONAL //
/*************************************************************************************/
.caja-busqueda-sticky{
  position: sticky !important;
  top: 0;
  z-index: 1;
  background-color: white;
}

/*************************************************************************************/
// BUSCADOR DE TABLA GESTIÓN PROYECTOS//
/*************************************************************************************/

.gestion-proyectos-resultados{
  .contenedor-encabezado-tabla{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .boton-crear{
      order: 2;
    }
    .filtro-flex{
      justify-content: center;
      flex-wrap: wrap;
      order: 1;
      input{
        min-width: 332px;
      }
    }

    @media screen and (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
      .boton-crear{
        order: 1;
      }
      .filtro-flex{
        order: 2;
      }
    }
    
  }
}