@import 'variables';
$tipos: (
  'primario': $color_primario,
  'secundario': $color_secundario,
  'oscuro': $color_oscuro
);
$lados: 'top', 'left', 'right', 'bottom';

@each $nombre, $color in $tipos {
  .tooltip-#{$nombre} {
    @each $lado in $lados {
      &.p-tooltip-#{$lado} .p-tooltip-arrow {
        border-#{$lado}-color: $color !important;
      }
    }

    .p-tooltip-text {
      background-color: $color !important;
    }
  }
}
