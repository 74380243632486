$color_principal: #18314f;

$tablet_breakpoint_height: 47em; // 752px //
$tablet_breakpoint_width: 48em; // 768px //
$large_tablet_breakpoing_width: 64em; // 1024px //
$desktop_breakpoint: 70em; // 1120 //
$small_screen_only: 'screen and (max-width: '+$tablet_breakpoint_width+') and (max-height: '+$tablet_breakpoint_height+')';
$small_screen_up: 'screen and (max-width: '+$tablet_breakpoint_width+')';
$small_screen_only_landscape: 'screen and (max-width: '+$tablet_breakpoint_width+') and (max-height: '+$tablet_breakpoint_height+') and (orientation: landscape)';
$small_tablet_screen_only: 'screen and (max-width: '+$desktop_breakpoint+')';
$tablet_screen_only: 'screen and (min-width: '+$tablet_breakpoint_width+') and (max-width: '+$large_tablet_breakpoing_width+')';
$tablet_screen_up: 'screen and (min-width: '+$tablet_breakpoint_width+')';
$large_tablet_screen_only: 'screen and (min-width: '+$large_tablet_breakpoing_width+') and (max-width: '+$desktop_breakpoint+')';
$large_tablet_screen_up: 'screen and (min-width: '+$large_tablet_breakpoing_width+')';
$desktop_screen_up: 'screen and (min-width: '+$desktop_breakpoint+')';
$duracion_animaciones_admin: 0.15s;
$color_primario: #6a88b1;
$color_secundario: #3e608f;
$color_oscuro: #626469;
$color_alerta: #e53935;
$color_alerta_secundario: darken($color_alerta, 10%);
$color_deshabilitado: lighten($color_primario, 20%);
$color_deshabilitado_oscuro: lighten($color_secundario, 20%);
$color_gris_documento: #91ABBD;
$color_boton_verde: #2A9D8F;
$color_boton_rojo: #E76F51;
//Asientos vuelos
$asiento_libre: #c1cfd9;
$asiento_ocupado: #cf4937;
$asiento_seleccionado: #009bf2;
$asiento_reservado: lightgray;
$asiento_desactivado: #F0F2F6;
//Beto-Breakpoints
$breakpoint_mobile_max_width: 47.99em; // 767.98px
$breakpoint_tablet_min_width: 48em; // 768px
$breakpoint_tablet_max_width: 63.99em; // 1023.98px
$breakpoint_desktop_min_width: 64em; // 1024px
