@font-face {
  font-family: 'fontello';
  src: url('/assets/fonts/font_icons/fontello.eot?95216868');
  src: url('/assets/fonts/font_icons/fontello.eot?95216868#iefix') format('embedded-opentype'),
       url('/assets/fonts/font_icons/fontello.woff2?95216868') format('woff2'),
       url('/assets/fonts/font_icons/fontello.woff?95216868') format('woff'),
       url('/assets/fonts/font_icons/fontello.ttf?95216868') format('truetype'),
       url('/assets/fonts/font_icons/fontello.svg?95216868#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/assets/fonts/font_icons/fontello.svg?95216868#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-alerta:before { content: '\e800'; } /* '' */
.icon-standard:before { content: '\e801'; } /* '' */
.icon-salida-avion:before { content: '\e802'; } /* '' */
.icon-comida-avion:before { content: '\e803'; } /* '' */
.icon-bebida-avion:before { content: '\e804'; } /* '' */
.icon-aseo-avion:before { content: '\e805'; } /* '' */
.icon-asiento-reservado:before { content: '\e806'; } /* '' */
.icon-capacidades-diferentes:before { content: '\e807'; } /* '' */
.icon-H_AC:before { content: '\e808'; } /* '' */
.icon-H_ASC:before { content: '\e809'; } /* '' */
.icon-H_LIM:before { content: '\e80a'; } /* '' */
.icon-booking:before { content: '\e817'; } /* '' */
.icon-goglobal:before { content: '\e818'; } /* '' */
.icon-ratehawk:before { content: '\e819'; } /* '' */
.icon-aeropuerto:before { content: '\e81a'; } /* '' */
.icon-teldar:before { content: '\e81b'; } /* '' */
.icon-w2m:before { content: '\e81c'; } /* '' */
.icon-consultia-fuel:before { content: '\e81d'; } /* '' */
.icon-bedsonline:before { content: '\e81e'; } /* '' */
.icon-vuelos:before { content: '\e81f'; } /* '' */
.icon-amadeus:before { content: '\e820'; } /* '' */
.icon-restel:before { content: '\e821'; } /* '' */
.icon-bus:before { content: '\e822'; } /* '' */
.icon-rentacar:before { content: '\e823'; } /* '' */
.icon-consultia-transmission:before { content: '\e824'; } /* '' */
.icon-camping:before { content: '\e825'; } /* '' */
.icon-marker-con-mapa:before { content: '\e82e'; } /* '' */
.icon-check-in-hotel:before { content: '\e82f'; } /* '' */
.icon-check-out-hotel:before { content: '\e830'; } /* '' */
.icon-car-door:before { content: '\e831'; } /* '' */
.icon-barco:before { content: '\e835'; } /* '' */
.icon-H_PIS:before { content: '\e838'; } /* '' */
.icon-icono-navbar-destinux-green:before { content: '\e839'; } /* '' */
.icon-icono_presupuesto:before { content: '\e83b'; } /* '' */
.icon-barcotraced:before { content: '\e83c'; } /* '' */
.icon-butaca:before { content: '\e83d'; } /* '' */
.icon-camarote:before { content: '\e83e'; } /* '' */
.icon-ferry:before { content: '\e83f'; } /* '' */
.icon-suite:before { content: '\e840'; } /* '' */
.icon-consultia-dollar_ko:before { content: '\e841'; } /* '' */
.icon-consultia-suitcase_ko:before { content: '\e842'; } /* '' */
.icon-consultia-factura:before { content: '\e843'; } /* '' */
.icon-consultia-front-car:before { content: '\e844'; } /* '' */
.icon-consultia-informe:before { content: '\e845'; } /* '' */
.icon-consultia-bed:before { content: '\e846'; } /* '' */
.icon-consultia-usuarios:before { content: '\e847'; } /* '' */
.icon-consultia-avion:before { content: '\e848'; } /* '' */
.icon-consultia-consumo:before { content: '\e849'; } /* '' */
.icon-consultia-gestor:before { content: '\e84a'; } /* '' */
.icon-consultia-pago:before { content: '\e84b'; } /* '' */
.icon-consultia-analisis-consumo:before { content: '\e84c'; } /* '' */
.icon-consultia-agenda:before { content: '\e84d'; } /* '' */
.icon-consultia-descarga:before { content: '\e84e'; } /* '' */
.icon-consultia-estrucutra:before { content: '\e84f'; } /* '' */
.icon-consultia-star:before { content: '\e850'; } /* '' */
.icon-consultia-star-empty:before { content: '\e851'; } /* '' */
.icon-fabrica:before { content: '\e854'; } /* '' */
.icon-golf:before { content: '\e855'; } /* '' */
.icon-hospital:before { content: '\e856'; } /* '' */
.icon-paisaje:before { content: '\e859'; } /* '' */
.icon-hotel-1:before { content: '\e85a'; } /* '' */
.icon-H_GIM:before { content: '\e85b'; } /* '' */
.icon-H_CFU:before { content: '\e85c'; } /* '' */
.icon-H_PAR:before { content: '\e85d'; } /* '' */
.icon-compras:before { content: '\e85f'; } /* '' */
.icon-consultia-plus:before { content: '\e860'; } /* '' */
.icon-estadio:before { content: '\e8bc'; } /* '' */
.icon-playa:before { content: '\e926'; } /* '' */
.icon-feria-muestras:before { content: '\f0f2'; } /* '' */
.icon-restaurante:before { content: '\f0f5'; } /* '' */
.icon-euro:before { content: '\f153'; } /* '' */
.icon-monumento:before { content: '\f19c'; } /* '' */
.icon-edificio:before { content: '\f1ad'; } /* '' */
.icon-taxi:before { content: '\f1ba'; } /* '' */
.icon-H_INT:before { content: '\f1eb'; } /* '' */
