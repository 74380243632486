// CSS para PopUps de los mapas de Rent a Car
.MyPopupRentacarOficinas {
  p {
    margin: auto !important;
  }
  .nombre {
    font-size: 1.17em;
    font-weight: bold;
  }
  .direccion {
    margin-top: 10px !important;
    font-weight: bold;
    font-size: 14px;
  }
  .detallesDireccion {
    font-size: 19px;
    font-weight: bold;
    line-height: normal;
  }
  .precioDesde {
    margin-top: 8px !important;
    font-weight: bold;
    font-size: 14px;
    text-align: end;
  }
  .pvpEntero {
    font-size: 35px;
    font-weight: bold;
    margin-top: -8px !important;
    text-align: end;
  }
  .pvpDecimales {
    font-size: 50%;
    padding-right: 4px;
  }
}
.MyPopupRentacarLocalizacion {

  .nombreLocalizacion {
    font-size: 1.17em;
    font-weight: bold;
     margin: auto;
  }
}

.miniIconosRentacar {
  .nombreLocalizacion {
    font-size: 1.17em;
    font-weight: bold;
    margin: auto;
  }
}

// CSS para los PopUps de los mapas de Hoteles

.MyPopupHoteles {
  .miTooltip {
    position: relative;
    display: inline-block;
  }
  .miTooltip .miTooltiptext {
    visibility: hidden;
    width: max-content;
    height: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 10px 4px 10px 4px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    top: -6px;
    right: 145%;
  }
  .miTooltip .miTooltiptext::after {
    content: '';
    position: absolute;
    top: 30%;
    left: 100%;
    margin-left: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }
  .miTooltip:hover .miTooltiptext {
    visibility: visible;
  }
  .miTooltipServicios {
    position: relative;
    display: inline-block;
  }
  .miTooltipServicios .miTooltiptextServicios {
    visibility: hidden;
    width: max-content;
    height: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 10px 7px 10px 7px;
    position: absolute;
    z-index: 1;
    top: -11px;
    left: 125%;
  }
  .miTooltipServicios .miTooltiptextServicios::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  .miTooltipServicios:hover .miTooltiptextServicios {
    visibility: visible;
  }
  .parent {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: auto repeat(2, auto);
    grid-template-rows: repeat(5, auto) auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  p {
    font-weight: bold;
  }
  .div1 {
    grid-area: 1 / 1 / 5 / 2;
  }
  .div2 {
    grid-area: 5 / 1 / 6 / 3;
    margin-top: 5px;
  }

  .div4 {
    grid-area: 1 / 2 / 4 / 4;
    margin-left: 5px;
  }
  .icono {
    height: 10px;
  }
  .leaflet-popup-content p {
    margin: 0px;
  }
  .divAlerta {
    text-align: end;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .alerta,
  .favoritoEstrella {
    width: 20px;
    img {
      width: 100%;
      height: auto;
      margin-top: 0px;
    }
  }
  .servicios{
    margin-top: 0px;
  }
  .codigoPais{
    vertical-align: bottom;
  }
  .desde {
    margin: 0px;
    text-align: right;
  }
  .pvpEntero {
    margin: 0px;
    text-align: right;
    font-size: 20px;
  }
  .pvpDecimales{
    font-size: 50%
  }
  .pvpMedio {
    text-align: end;
    margin-top: 0px;
  }
  .nombre {
    margin-top: 0px;
    font-size: 15px;
    line-height: 1.3;
  }
  .box img{
    width: 150px;
    height: auto;
  }
  @supports(object-fit: cover){
      .box img{
        border-radius: 10px;
        height: 100px;
        object-fit: cover;
        object-position: center center;
      }
  }
}

.MyPopupHotelesLocPers {

  .tituloLocalizacionPersonalizada {
    font-size: 1.17em;
    font-weight: bold;
    margin: auto;
    color: red;
  }
  .texto {
    margin: 0px;
  }
}

.MyPopupHotelesLocalizacion p {

  .nombrelocalizacion{
    font-size: 1.17em;
    font-weight: bold;
    margin: auto;
  }
  .texto{
    margin: 0px;
  }
}

.miniIconosHoteles {
  .iconoMiniOnHover{
    height: 30px;
  }
  .iconoMiniOfHover {
    height: 40px;
    position: absolute;
    top: -10px;
    left: -3px;
  }
}

.MyPopupHoteles .botonEstrella {
  outline: none;
  float: right;
  width: 100%;
  margin-top: 14px;
  text-align: end;
}

.MyPopupHoteles .div3 {
  grid-area: 5/3/6/4;
  vertical-align: text-bottom;
  margin-left: 15px;
  position: absolute;
    /* margin-bottom: 0px; */
    bottom: 0px;
    right: 0px;
}

.MyPopupHoteles .div5 {
  grid-area: 6/1/6/4;
}

.MyPopupHoteles .leaflet-popup-content {
  width: 340px !important;
}

.MyPopupHoteles   .imagenHotel {
  min-width: 150px;
}

.MyPopupHoteles {
  left: -190px !important;
}

@media screen and (max-width: 500px) and (min-width:300px) {

  .MyPopupHoteles {
    left: -135px !important;
  }

.MyPopupHoteles .leaflet-popup-content {
  width: 230px !important;
}

.MyPopupHoteles   .imagenHotel {
  min-width: 100px;
}
}

//clase para popup geolocalización

.swal-geoposion-content-class {
  max-height: 40em;
  overflow: auto;
}